.main-container{
    width: 100%;
    // max-width: 1300px;
    z-index: 1;
    margin: auto;
    // padding-bottom: 4rem;

    // @media (max-width: 768px) {
    //     width: 100%
    // }

    @media (min-width: 768px) {
        width: 100%
    }

    @media (min-width: 769px) {
        width: 90%;
    }
}

.spacer-100{
    padding: 100px;
}

.spacer-50{
    padding: 5rem;
}

.spacer-vertical{
    position: relative;
    float: left;
    height: 100%;
    border: solid transparent;
}