@import "../abstracts/colors";

.form_inner-container{
    margin: 1rem 0;
  }
  
  input{
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: $grey solid 1px;
    width: 100%;
    text-align: left;
    padding-top: 1rem;
  }

  .no-border{
    border-bottom:none !important;
  }
  
  .form-select{
  
    @media only screen and (max-width: 768px) {
      padding: 2rem !important;
  }
  
    margin:0;
    display:flex;
    flex-direction:column;
    margin-bottom: 3rem;
    // .input-group{ 
    //   border-bottom: $grey solid 1px;
    //   width: 100%;
    //   text-align: left;
    //   padding-top: 1rem;
    // }

    .label {
      display: inline-block;
      padding-left: 3rem;
      background: url("../../svg/box-uncheked.svg") no-repeat left center; 
      // /* padding-left: 15px; */
    }
    input[type=checkbox]:checked + .label {
      background-image: url("../../svg/box-checked.svg");
    }

    input[type=checkbox] {
      display: none;
    }

    .input-group{
      display:flex;
      padding: 10px 0;
    }
    
    .input-group label {
      display: block !important;
      cursor: pointer;
      min-width: 300px;
      max-width: 90%;
    }

    div.sticky-bottom{
        height: 5rem;
        position:sticky;
        top:0;
        bottom:2.5rem;
        .btn-container{
          margin-top: 3rem;
          display: flex;
          background-color: inherit;
          .next{
            margin-left: auto;
          }
          .previous{
            margin-right: auto;
          }
          button{
            width: 15%;
            @media only screen and (max-width: 768px) {
              width: 25%;
            }
          }
          .btn{
            width: 15%;
            @media only screen and (max-width: 768px) {
              width: 25%;
            }
          }
        }
    }
  }

  .form-page-spacer{
    height: 400px
  }
  
  .form-default {
    input{ 
      border-bottom: $grey solid 1px;
      width: 100%;
      text-align: left;
      padding-top: 1rem;
    }

    .label {
      display: inline-block;
      padding-left: 3rem;
      background: url("../../svg/box-uncheked.svg") no-repeat left center; 
      // /* padding-left: 15px; */
    }
    input[type=checkbox]:checked + .label {
      background-image: url("../../svg/box-checked.svg");
    }

    input[type=checkbox] {
      display: none;
    }


    .input-group{
      display:flex;
      padding: 10px 0;
    }
    .input-group label {
      display: block !important;
      cursor: pointer;
      min-width: 300px;
      max-width: 90%;
    }
  
    .form__input-outter-wrapper{
        margin-bottom: 2rem;
        @media only screen and (min-width: 769px) {
          padding-top: 2rem !important;
      }
        .form__input-wrapper{
            position: relative;
            margin-bottom: .5rem;
          }
    }
    
  }
  
  .input-with-icon{
    position: relative;
    .icon{
      position: absolute;
      right: 0;
      bottom: 1rem;
      cursor: pointer;
    }
  }

  .icon{
    cursor: pointer;
    padding: 4px 10px;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: lowercase;
    font-style: normal;
  }

  .icon-b{
    position: absolute;
    transform: translate(0, 50%);
    right: 0;
    bottom: 1.7rem;
    cursor: pointer;
    padding: 4px 10px;
    background-color: white;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: lowercase;
    font-style: normal;
  }

  .icon-t{
    position: absolute;
    transform: translate(0, 50%);
    right: 0;
    top: 0;
    cursor: pointer;
    padding: 6px 10px;
    background-color: white;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: lowercase;
    font-style: normal;
  }

  .label-main{
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
  }
  
  .label-subtitle{
    font-size: 1rem;
    font-style: italic;
    line-height: 1.2rem
  }
  
  .options-position-right{
    margin-left: 80px;
  }
  

  