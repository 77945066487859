.header {
    text-align: center;
    font-weight: 200;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 300px;
    margin: auto;
    @media only screen and (max-width: 768px) {
        width: 80%;
    }
    &__title {
        font-size: 2.4rem;   
        line-height: 2.4rem;
        @media only screen and (max-width: 768px) {
            font-weight: 400;
        }
    }

    &__subheading{
        font-size: 1.6rem;
        line-height: 2.4rem;
        @media only screen and (max-width: 768px) {
            font-weight: 300;
        }
    }

    &__icon {
        margin-bottom: 3rem;

        @media only screen and (max-width: 320px) {
 
        }
    }
}

.no-header{
    min-height: 1px;
    @media only screen and (max-width: 768px) {
        min-height: 1px;
    }
}


.modal > .header__title{
    font-size: 2rem;   
    line-height: 2.4rem;
    font-weight: 100;
    @media only screen and (max-width: 768px) {
        font-weight: 400;
    }
}
.modal > .header__subheading{
    font-size: 1.6rem;   
    line-height: 2rem;
    font-weight: 300;
    @media only screen and (max-width: 768px) {
        font-weight: 300;
    }
}

.modal-select > .header{
    min-height: 200px !important;
}
