.login-page{
    width: 30% !important;
    @media only screen and (max-width: 768px) {
        width: 100% !important;
    }
}
.auth{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-height: 100vh;
    @media only screen and (max-width: 768px) {
        width: 100%;
        justify-content: center;
        background: linear-gradient(0deg, #F2F8FF 10%, rgba(240, 229, 255, 0) 94.88%);
        padding-bottom: 5rem;
    }
    &__btn-container{
        min-height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width:80%;
        @media only screen and (max-width: 768px) {
            min-height: 200px;
            width: 65%;
        }
    }
    .login{
        min-height: 140px;
    }
    & button{
        width: 100%;
        padding: 1.5rem 2rem;
    }
    .header{
        min-height: 250px;
        margin: 1rem 0;
        @media only screen and (max-width: 768px) {
            min-height: 280px;
            margin: 1rem 0;
        }
    }
    .login-header{
        min-height: 150px;
    }
    .back-arrow{
        position: absolute;
        top: 0;
        left: 20%;
        padding: 3rem 2rem;
        @media only screen and (max-width: 768px) {
            position: absolute;
            top: 0;
            left: 0;
            padding: 3rem 2rem;
        }
    }

    form{
        width: 80%;
        @media only screen and (max-width: 768px) {
            width: 85%;
        }
        .icon img{
            width: 2.3rem
        }
    }
    
    .login_btn{
        width: 80%
    }
}
