@import '../../assets/scss/abstracts/colors';


.carousel {
  display: flex;
  margin: 1rem 1rem;
  @media only screen and (max-width: 768px) {
    margin-top: -5rem;
    margin-bottom: 2rem;
  }

  .direction {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    width: 20%;
    @media only screen and (max-width: 768px) {
      width: 10%;
    }

    .arrow {
      cursor: pointer;
    }

    .arrow-left{
      -webkit-transform:rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .card-div {
    width: 95%;
    // position: relative;

    .card-scroll {
      display: flex;
      overflow: hidden;
      background-color: $light-grey;
      max-width: 100%;
      border: $light-grey 3px solid;
      .card-title{
          font-size: 1.4rem;
          position: absolute;
          top: -2rem
      }
      img{ object-fit: contain;
        max-width: 100%;
      }
    }
  }
}

.carousel-slide{
  .slide-title{
    position: absolute;
    bottom: 0;
    margin: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2rem;
    overflow: hidden;
    color: $violet;
    @media only screen and (max-width: 768px) {
      font-size: 1.4rem;
      line-height: 1.6rem;
      background-color: $violet;
      color: white;
      width: 100%;
      margin-bottom: -1rem;
      height: 2rem;
    }
  }
  img{
    max-width: 50%;
  }
}

.slider-wrapper{
  position: relative;
}
.carousel-slide{
  background-color: $light-grey !important;
}

.awssld__content{
  background-color: $light-grey !important;
}

.awssld__bullets button{
  background-color: $light-grey !important;
  border: 1px solid $grey;
  width: 1rem !important;
  height: 1rem !important;
}
