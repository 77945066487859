@import '../../assets/scss/abstracts/colors';
.home{
    display: flex;
    display: -webkit-flex; /* Safari */     
    flex-direction: row-reverse;
    justify-content: space-between;
    @media only screen and (max-width:600px) {
        flex-direction: column;
     }
    &__container{
        margin-left: 120px;
        overflow: hidden;
        max-height: 100vh;
        // position: fixed;
        right: 1rem;
        // width: 100%;
        right: 0;
        left: 0rem;
        @media only screen and (max-width:600px) {
           margin-left: 0;
           left: 0;
        }
        @media (max-width:768px) and (min-width:600px) {
            margin-left: 0;
            left: 0;
            
        }
    }

    &__col-right{
        -webkit-flex-shrink: 1; /* Safari */
        -ms-flex-shrink: 1; /* IE 10 */
        -ms-flex-basis: 60%;
        -webkit-flex-basis: 60%;
        flex-shrink: 0;
        flex-basis: 60%;
        margin-bottom: 1rem;
        @media only screen and (max-width:600px) {
            position: initial;
            -webkit-flex-shrink: 1; /* Safari */
            -ms-flex-shrink: 1; /* IE 10 */
            -ms-flex-basis: 100%;
            -webkit-flex-basis: 100%;
            flex-shrink: 0;
            flex-basis: 100%;
            margin-bottom: 1rem;
            // max-height: 40vh;
            min-height: 30vh;
         }
    }

    &__col-left{
        -webkit-flex-shrink: 1; /* Safari */
        -ms-flex-shrink: 1; /* IE 10 */
        -ms-flex-basis: 36%;
        -webkit-flex-basis: 36%;
        flex-shrink: 0;
        flex-basis: 36%;
        margin-bottom: 1rem;
        @media only screen and (max-width:600px) {
            position: initial;
            -webkit-flex-shrink: 1; /* Safari */
            -ms-flex-shrink: 1; /* IE 10 */
            -ms-flex-basis: 100%;
            -webkit-flex-basis: 100%;
            flex-shrink: 0;
            flex-basis: 100%;
            margin-bottom: 1rem;
            // max-height: 70vh;
            min-height: 60vh;
         }
         @media (max-width:768px) and (min-width:501px) {
            position: absolute;
            width: 100vw;
            top: 310px;
        }
    }


    &__map_wrapper{
        position: relative;
        .map-toggle-wrapper{
            background: white;
            display: flex;
            width: 175px;
            height: 32px;
            position: absolute;
            top: 1rem;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            transform: translateX(-50%);
            z-index: 999;
            justify-content: space-evenly;
            border-radius: 14px;
            box-shadow: 0px 4px 4px rgba(115, 76, 184, 0.25);
            @media only screen and (max-width:600px) {
                width: 75px;
            }
            .round >img{
                padding: 0.6rem .8rem;
                cursor: pointer;
            }
        }
        .map-arrow-icon{
            position: absolute;
            top: 1rem;
            left: 2rem;
            z-index: 999;
            img{
                padding: 0.8rem;
                cursor: pointer;
                background: white;
                border-radius: 50%;
            }
        }
    }
    &__search-row{
        box-shadow: 0 4px 4px #e1dde1;
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        width: 111%;
        margin: auto;
        padding: 1rem 0;
        @media only screen and (max-width:600px) {
            padding: 0 1rem 1rem 1rem;
            box-shadow: 0px 4px 4px #E1DDE1;
            width: 100%
          }

        .filter-icon{
            padding-right: 2rem;
            margin-left: 1rem;
            @media only screen and (max-width:600px) {
                left: 0;
                padding-right: 1rem;
                margin-top: 1rem;
              }
        }
        .search-input-icon{
            position: relative;
            left: -3rem;
            @media only screen and (max-width:600px) {
                left: 0;
                top: 1rem;
                height: 2.8rem;
              }
        }
    }
    &__search-result-container{
        margin-top: 0rem;
        // height: 100vh;
        overflow: scroll;
        transform: translateY(0%);
        transition: transform 0.5s;
        
        @media only screen and (max-width:600px) {
            margin-top: 0rem;
        }
        .profile__cardlist-container{
            padding-bottom: .5rem;
           
            @media only screen and (max-width:600px) {
                min-height: 33px;
                max-height: 35vh;
              }
            .card-wrapper:nth-last-child(2){
                margin-bottom: 4rem;
            }
            
        }
        .total-results{
            text-align:center;
            font-size: 1.6rem;
            font-weight: 400;
            color: $grey;
            padding: 1rem 0;

            @media only screen and (max-width:600px) {
                background-color: $light-grey-transperant;
                color: $black;
              }
            .close-results{
                margin-right: 2rem;
                font-size: 1.8rem;
            }
        }

    }
    // .card-wrapper:last-child{
    //     margin-bottom: 4rem;
    // }
    
}
.hide-map{
        @media only screen and (max-width:600px) {
            max-height: 88vh !important;
          }
        
}
.arrow-icon{
    padding: .5rem 1rem;
    margin-top:1.3rem;
    img{
        height: 1.2rem;
    }
}

.bg-dark{
    background: $black;
    pointer-events:none; 
}

.bg-light{
    background: white;
}

.round{
    width: 50%;
    border-radius: 14px;
    text-align: center;
    @media only screen and (max-width:600px) {
        width: fit-content;
        border-radius: 50%;
    }
}

#search-map {
    top: 0; 
    bottom: 0; 
    width: 100%;
    height: 100vh;
    overflow: auto;
    @media only screen and (max-width:600px) {
      min-height: 300px;
      height: 310px;
    }
  }  

.navigation-control{
    position: absolute;
    right: 5px;
    top: 5px;
}  

.mapboxgl-ctrl-attrib-inner{
    display: none;
}

#event-result-container{
    padding-bottom: 3.5rem;
    @media only screen and (max-width:600px) {
        // padding: 0 2rem 3.5rem 2rem;
        margin-top: 1rem;
    }
    .profile__cardlist-container {
        @media only screen and (max-width:600px) {
            min-height: 33vh;
        }
       
    }
    .event-card__container-wrapper{
        max-height: 13rem;
        min-height: 12.5rem;
        overflow: hidden;
        @media only screen and (max-width:600px) {
            max-height: 11rem;
            min-height: 10.5rem;
        }
    }
}
