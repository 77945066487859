$red: #FF5B5B;
$bright-red: #DC4D40;
$black: #3B3447;
$primary-font: #3B3447;
$grey: #878EA1;
$light-grey: #DADADA;
$medium-grey: #C5CED9;
$light-grey-transperant: rgba(218, 218, 218, .5);
$lighter-grey: #FAFAFA;
$light-blue: #5C8FE0;
$blue: #1E77F2;
$violet: #7245B2;
$violet-faded: #E8DFF3;
$red: #E05374;
$orange:#FF9F84;

$bg-primary: #fff;

$bg-linear: linear-gradient(0deg, #F2F8FF 0%, rgba(240, 229, 255, 0) 94.88%);

$bg-linear-light: linear-gradient(360deg, #FFEEE9 10.07%, #EFE4FF 100%);

