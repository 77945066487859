@import "./assets/scss/abstracts/variables";
@import "./assets/scss/abstracts/colors.scss";
@import './assets/scss/utils/_general.scss';
@import './assets/scss/elements/_button.scss';
@import './assets/scss/abstracts/_colors.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%
}

// 1 rem set to 10px
html {
  font-size: 62.5%;
  overflow:   scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

body {
  font-family: $font-main;
  color: $primary-font;
}
input, textarea{
  font-family: $font-main;
}

//shared
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

h1, h2{
  font-weight: lighter;
  font-size: 2.4rem;
}

h3{
  font-weight: 300;
  font-size: 2rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

textarea{
  font-size: 1.6rem;
  color: $black;
  font-weight: 300;
  line-height: 2.2rem;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}


input{
  background: transparent;
}

label{
  font-size: 1.6rem;
  color: $grey;
  font-weight: 200;
  text-transform: lowercase;;
}

a{
  text-decoration: none;
  color: inherit;
  font-weight: 500 ;
}

input{
  padding-bottom: .4rem;
}

::-webkit-input-placeholder { /* Edge */
  color: $grey;
  font-size: $font-size-regular;
  font-style: italic;
  font-weight: $regular;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:$grey;
  font-size: $font-size-regular;
  font-style: italic;
  font-weight: $regular;

}

::placeholder {
  color: $grey;
  font-size: $font-size-regular;
  font-weight: $regular;
  font-style: italic;
}

input[type="text"] {
  font-size: $font-size-regular;
  font-weight: $regular;
}

input[type="password"] {
  font-size: $font-size-regular;
  font-weight: $regular;
}


//remove background color on Chrome autocomplete fields
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
